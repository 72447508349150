import "./ServiceVideo.css";

import React from "react";
import backgroundimg from "./sample.jpeg"; // Replace with your video file path

const BackgroundVideo = () => {
  return (
    <div className="background-video-container">
     <img  src={backgroundimg} className="background-video bgm" alt="img" ></img>
     
      <div className="content-overlay">
        <h2>Drive Innovation and Growth with Confluent Cloud, Apache Kafka, and Apache Flink for Real-Time Business Insights.</h2>
        {/* <p>Enjoy this video background experience.</p> */}
      </div>
    </div>
  );
};

export default BackgroundVideo;
