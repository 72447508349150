import { Achievements } from './Achivements/Achivements';
import { Cards } from './Cards/Cards';
import Career from './Career/Career';
import  Carousel  from './Carousel/Carousel';
import { Footer } from '../Footer/Footer';
import { LearnExperts } from './Learn/Learn';
import Meta from '../../Meta';
import React from 'react'
import Solution from './Solutions/Solutions';
import { Strategy } from './Strategy/Strategy';
import StructuredData from '../StructuredData';
import { TechnologyPartner } from './Tech/Technology';

function Home() {
  return (
    <div>
    <Meta
    title="Xstream Minds Pvt Ltd - The Way Digital"
    description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
    keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
  />
  <StructuredData></StructuredData>
      <Carousel></Carousel>
      <Achievements></Achievements>
      <TechnologyPartner></TechnologyPartner>
      <Solution></Solution>
      <Strategy></Strategy>
      <Cards></Cards>
      <Career/>
      <LearnExperts></LearnExperts>
      <Footer></Footer>
      
    </div>
  )
}

export default Home
