import BackgroundVideo from "./ServiceVideo";
import { Footer } from "../Footer/Footer";
import Meta from "../../Meta";
import React from "react";
import ServiceDataFrame from "./ServiceDataFrame";
import { ServiceDigital } from "./ServiceDigital";
import StructuredData from "../StructuredData";

function Services() {
  return (
    <div>
    <main>
    <StructuredData></StructuredData>
      {" "}
      <Meta
      title="Xstream Minds Pvt Ltd - The Way Digital"
      description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
      keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
    />
      <BackgroundVideo></BackgroundVideo>
      <ServiceDigital></ServiceDigital>
      <ServiceDataFrame></ServiceDataFrame>
      <Footer></Footer>
      </main>
    </div>
  );
}

export default Services;
