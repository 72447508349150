import Demo from './Demo';
import { Footer } from '../Footer/Footer';
import Meta from '../../Meta';
import React from 'react'
import Slider from './Slider'
import StructuredData from '../StructuredData';

function Contact() {
  return (
    <div>
    <Meta
    title="Xstream Minds Pvt Ltd - The Way Digital"
    description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
    keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
  />
  <StructuredData/>
      <Slider></Slider>
      <Demo></Demo>
      <Footer></Footer>
    </div>
  )
}

export default Contact;
