import { Helmet } from 'react-helmet-async';
import React from 'react';

const StructuredData = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Xstream Minds Pvt Ltd",
          "url": "https://xstreamminds.com",
          "logo": "https://xstreamminds.com/logo.png",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1 513 342 1033",
             "mobile": "+91 40 3562 4447",
            "contactType": "Customer Service",
            "areaServed": "IN",
            "availableLanguage": "English"
          },
          "sameAs": [
            "https://www.facebook.com/xstreamminds",
            "https://x.com/Xstream_Minds",
            "https://www.linkedin.com/company/xstream-minds-pvt-ltd/"
          ]
        }
      `}
    </script>
  </Helmet>
);

export default StructuredData;