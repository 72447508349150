import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";

import { Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import Meta from "../../Meta";
import React from "react";
import footerimg from "./logo2.png";

export const Footer = () => {
  return (
    <footer className="footer-section">
    <Meta
    title="Xstream Minds Pvt Ltd - The Way Digital"
    description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
    keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
  />
      <Container>
      
        <Row>
          <Col md={3} sm={6} className="footer-logo">
            <Link to="/">
              {" "}
              <img
                src={footerimg} // Replace with your logo path
                alt="Xstream Minds Logo"
                className="logo"
              />
            </Link>
            <div className="footer-container">
            
              <h5 className="loc">Corporate Office Location:</h5>
              <p className="footer-location">
              402, Sri Geetanjali Towers, Beside Nexus Mall, Kukatpally Housing
              Board Colony, Hyderabad, Telangana, India 500072.
            </p>
            </div>
          </Col>
          <Col md={3} sm={6} className="footer-services">
            <h5>Services</h5>
            <ul>
              <a
                href="/services#Service_Nav"
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>Confluent Cloud</li>
              </a>
              <a
                href="/services#Service_Nav"
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>Apache kafka</li>
              </a>
              <a
                href="/services#Service_Nav"
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>Apache Flink</li>
              </a>
              <a
                href="/services#Service_Nav"
                style={{ textDecoration: "none", color: "white" }}
              >
                {" "}
                <li>Full Stack Development</li>
              </a>
            </ul>
          </Col>
          <Col md={3} sm={6} className="footer-contact">
            <h5>Contact Us</h5>

            <p>
              <i class="bi bi-envelope m-2"></i> : sales@xstreamminds.com <br />
            </p>

            <p>
              {" "}
              <i class="bi bi-telephone m-2"></i> : +1 513 342 1033
            </p>
            <p>
              {" "}
              <i class="bi bi-phone m-2"></i> : +91 40 3562 4447
            </p>

            <div className="social-icons">
              <a
                href="https://x.com/Xstream_Minds"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-twitter-x m-2"></i>
              </a>

              <a
                href="https://linkedin.com/company/xstream-minds-pvt-ltd"
                target="_blank"
                rel="noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </Col>
          <Col md={3} sm={6} className="footer-ctas">
            <a href="/contact#get">
              {" "}
              <button className="ctas-button">Join our Team</button>
            </a>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col>
            <p>
              <Link to="/privacy-policy" style={{ color: "white" }}>
                Privacy Policy
              </Link>{" "}
              - Terms of Services - Web Accessibility
            </p>
            <p>
              Copyright &copy; 2025 Xstream Minds Pvt Ltd. All rights Reserved
              by Xstream Minds Pvt Ltd.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
