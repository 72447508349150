import Beliefs from './Belief/Belief';
import { BussinessSection } from './Bussiness/BussinessSection'
import {Footer} from '../Footer/Footer';
import Meta from '../../Meta';
import { Potential } from './Potential/Potential'
import React from 'react';
import StructuredData from '../StructuredData';
import XstreamJourneyCard from './XstreamJourneyCard';
import { XstreamMinds } from './Inovation/Innovation';

function About() {
  return (
    <div>

    <Meta
        title="Xstream Minds Pvt Ltd - The Way Digital"
        description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
        keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
      />
<StructuredData></StructuredData>
      
      <Potential></Potential>
      <BussinessSection></BussinessSection>
      <Beliefs></Beliefs>
      <XstreamMinds></XstreamMinds>
      <XstreamJourneyCard></XstreamJourneyCard>
      <Footer></Footer>
    </div>
  )
}

export default About
