import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import About from "./Components/Aboutus/About";
import  Contact  from "./Components/Contact/Contact";
import DomainSpecific from "./Components/PrivacyPolicy/Privacy/DomainSpecific";
import { HelmetProvider } from "react-helmet-async";
import Home from "./Components/Home/Home";
import  Meta  from "../src/Meta";
import NotFound from "./Components/PageNotFound/NotFound";
import PrivacyPolicy from "./Components/PrivacyPolicy/Privacy/Privacy";
import RealWorldApp from "./Components/PrivacyPolicy/Privacy/RealWorldApp";
import Sample from "./Components/Navbar/Sample";
import Scroll from "./Components/Scroll";
import Services from "./Components/Services/Services";
import StructuredData from "./Components/StructuredData";
import { useEffect } from "react";

const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            const id = location.pathname.replace('/', ''); // Extract section id from pathname
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);

    return null; // No UI rendered by this component
};

function App() {
    return (
        <div className="App">
        <HelmetProvider>
            <BrowserRouter>
                <Sample />
                <ScrollToSection />
                <Scroll></Scroll>
                <StructuredData/>
                <Meta
                title="Xstream Minds Pvt Ltd - The Way Digital"
                description="Xstream Minds specializes in  Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development Services ."
                keywords="Confluent Cloud ,Apache Kafka,Apache Flink,Full stack development,Xstreamminds ."
              />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/WhoWeAre" element={<Home />} />
                    <Route path="/AboutUs" element={<About />} />
                    <Route path="/Career" element={<Home />} />
                    <Route path="/ser" element={<Services></Services>}></Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/services" element={<Services></Services>}/>
                    <Route path="/Real" element={<RealWorldApp/>}/>
                    <Route path="/domain" element={<DomainSpecific/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            </HelmetProvider>
        </div>
    );
}

export default App;
